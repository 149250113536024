main#help {
  padding: 2em;
  position: relative;

  .faq {
    font-size: .9em;
    .item {
      margin: 2rem 0;
      position: relative;
      & + .item:before {
        content: '';
        display: block;
        height: 2px;
        width: 30px;
        background-color: #2a87d0;
        position: absolute;
        top: -1rem;
        left: calc(50% - 15px);
      }
      .question {
        font-weight: 600;
        font-size: 1rem;
        padding-bottom: 1rem;
      }
      .answer {
        padding: 0 .5rem;
      }
    }
    ul {
      margin-left: 1em;
      padding-left: 0;
      li {
        margin-bottom: .5em;
      }
    }
  }
  dl {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-gap: .5em 2em;
    //dt, dd {
    //  margin-bottom: .5em;
    //}
    dt {
      grid-column: 1;
      white-space: nowrap;
    }
    dd {
      grid-column: 2;
      font-weight: 700;
    }
  }
}
