.newsletter {
  width: 500px !important;
  height: 300px !important;
  left: calc(50% - 250px) !important;
  top: calc(50% - 150px);
  z-index: 1002 !important;
  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > div:last-child {
      display: flex;
      align-items: center;
      input {
        margin-right: .5rem;
      }
      span {
        width: 2rem;
        svg {
          fill: #0E4D9F !important;
        }
      }
    }
  }
}
