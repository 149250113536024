.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1001;
}
.map-modal, .info-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1002;
  border-radius: 10px;
  box-shadow: 0 3px 50px rgba(0, 0, 0, .8);
  display: flex;
  flex-direction: column;
}
.info-modal {
  width: 45rem;
  align-items: center;
  overflow: auto;
  & > * {
    padding: 1.2rem 1.5rem;
  }
  main {
    border-top: 2px solid rgba(37, 53, 77, 0.2);
    border-bottom: 2px solid rgba(37, 53, 77, 0.2);
  }
  footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    background-color: #EFEFEF;
  }
}
.map-modal {
  width: 80vw;
  height: 90vh;
  padding: 2rem;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    h3 {
      text-align: left;
      color: #3E90FF;
    }
    & > div {
      display: grid;
      grid-template-columns: repeat(3, auto);
      gap: 1rem;
      & > div, .field {
        display: flex;
        align-items: center;
        grid-gap: .3rem;
        p {
          margin-left: .5rem;
        }
        label {
          margin: 0;
        }
        input {
          width: 8rem !important;
        }
      }
      & > div:last-child {
        input {
          width: 5rem !important;
        }
      }
    }
  }
  main {
    margin-block: 2rem;
    padding: 0;
    flex: 1 1 auto;
    .leaflet-container {
      width: 100%;
      height: 100%;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 500;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, .2);
      }
    }
  }

  footer {
    display: flex;
    justify-content: flex-end;
  }
}
